'use client';
import {Loader} from '@prescriberpoint/ui';
import {FC} from 'react';

const PageContentLoader: FC = () => (
  <div className='flex min-h-page-content w-full items-center justify-center'>
    <Loader />
  </div>
);

export default PageContentLoader;
