'use client';
import {Header, HeaderType} from '@prescriberpoint/ui';
import {FC} from 'react';
import {SponsoredCards, SearchBar} from '@/components';
import * as FLAGS from '@/constants/flags';
import {useFlag} from '@/context/FlagsContext';

const Index: FC = () => {
  const showAIPPT = useFlag(FLAGS.PPT_AI_COMPONENT);
  return (
    <div className='flex h-full flex-col space-y-2'>
      {!showAIPPT && (
        <div className='flex flex-1 flex-col items-center justify-center'>
          <div>
            <Header as={HeaderType.H2} className='text-4xl'>
              Explore drugs today.
            </Header>
          </div>
          <div className='center flex w-full items-center justify-center'>
            <div>
              <SearchBar size='lg' />
            </div>
          </div>
        </div>
      )}

      <div className='items-end'>
        <SponsoredCards />
      </div>
    </div>
  );
};

export default Index;
